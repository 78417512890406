import { template as template_b98fe73dd78f4c74b19c3dc67e7e0714 } from "@ember/template-compiler";
const SidebarSectionMessage = template_b98fe73dd78f4c74b19c3dc67e7e0714(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
