import { template as template_270941bd5ea34dffbe958ea165c754ac } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_270941bd5ea34dffbe958ea165c754ac(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
