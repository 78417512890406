import { template as template_f3c06f20f2154744a2bd937a3d43246d } from "@ember/template-compiler";
const FKText = template_f3c06f20f2154744a2bd937a3d43246d(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
