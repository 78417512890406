import { template as template_2f2b8fbdcb0940fba2379b94bd0a3f90 } from "@ember/template-compiler";
const FKControlMenuContainer = template_2f2b8fbdcb0940fba2379b94bd0a3f90(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
